import ORMModel from '@/core/bridge/orm/ORMModel';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import ProductsListItemCoreModel from '@/modules/products-lists/models/ProductsListItemCoreModel';
import { formatStringDate } from '@/core/helpers/utils/DateUtils';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class ProductsListCoreModel extends ORMModelExtended {
  public static entity = 'products-list';

  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/v2/products-lists',
      },
      get: {
        method: 'GET',
        url: '/v2/products-lists/:id',
      },
      create: {
        method: 'POST',
        url: '/v2/products-lists',
      },
      update: {
        method: 'PATCH',
        url: '/v2/products-lists/:id',
      },
      delete: {
        method: 'DELETE',
        url: '/v2/products-lists/:id',
      },
    },
  };

  public static fields() {
    return {
      id: this.number(null),
      name: this.string(''),
      boxNumber: this.string(''),
      number: this.string(''),
      order: this.number(''),
      date: this.string(''),
      hash: this.string(''),
      comments: this.string(null).nullable(),

      items: this.attr([]),
      itemIds: this.attr([]),

      createdAt: this.string('').nullable(),
      updatedAt: this.string('').nullable(),
      deletedAt: this.string('').nullable(),
    };
  }

  /**
   * Create print url
   * @param id
   * @param token
   */
  public static printUrl(id: number, token: string) {
    return `${process.env.VUE_APP_API_URL}/v2/products-lists/${id}/print?token=${token}`;
  }

  public id!: number;
  public name!: string;
  public boxNumber!: string;
  public number!: string;
  public order!: number;
  public date!: string;
  public hash!: string;
  public comments!: string | null;

  public items: ProductsListItemCoreModel[] = [];
  public itemIds!: ProductsListItemContract[];

  public createdAt!: string;
  public updatedAt!: string;
  public deletedAt!: string;

  /**
   * Get formatted created at date
   */
  public get createdAtFormatted(): string {
    return formatStringDate(this.createdAt);
  }

  /**
   * Get products
   */
  public get products(): ORMCollection {
    const products: ProductCoreModel[] = this.items.map((item: ProductsListItemCoreModel) => item.product);
    const productIds: number[] = products.map((product: ProductCoreModel) => product.id);

    return new ORMCollection(productIds, ProductCoreModel);
  }

  /**
   * Load items
   * @param items
   */
  public loadItems(items: ProductsListItemCoreModel[]) {
    items.forEach((item: ProductsListItemCoreModel) => this.addItem(item));
  }

  /**
   * Add item
   * @param item
   */
  public addItem(item: ProductsListItemCoreModel) {
    this.items.push(item);
  }

  /**
   * Remove item by product id
   * @param productId
   */
  public removeItem(productId: number) {
    const index: number = this.items.findIndex((item: ProductsListItemCoreModel) => item.productId === productId);

    if (index > -1) {
      this.items.splice(index, 1);
    }
  }

  /**
   * Prepare data to create request
   */
  public toCreate(): ProductListRequestContract {
    return this.toRequest();
  }

  /**
   * Prepare data to update request
   */
  public toUpdate(): ProductListRequestContract {
    return this.toRequest();
  }

  /**
   * Prepare data to request
   */
  public toRequest(): ProductListRequestContract {
    return {
      name: this.name,
      boxNumber: this.boxNumber,
      comments: this.comments,
      itemsData: this.itemsDataToRequest(),
    };
  }

  /**
   * Prpeare items data to request
   */
  public itemsDataToRequest(): ProductsListItemContract[] {
    const result: any[] = [];

    this.items.forEach((item: ProductsListItemCoreModel) => {
      result.push({
        productId: item.productId,
        quantity: item.quantity,
      });
    });

    return result;
  }
}

interface ProductListRequestContract {
  name: string;
  boxNumber: string;
  comments: string | null;
  itemsData: ProductsListItemContract[];
}

interface ProductsListItemContract {
  productId: number;
  quantity: number;
}
